<template>
  <EvaluationsTable :isOpcionSidebar="true" />
</template>

<script>
import EvaluationsTable from "@/views/profile/Evaluation/EvaluationsTable.vue";
import { onMounted } from 'vue-demi';

export default {
  components: {
    EvaluationsTable,
  },
  setup() {
    onMounted(() => {
      window.scrollTo( 0, 0 );
    });
  },
};
</script>

<style scoped>
</style>